// Copyright © 2025 Ory Corp
// SPDX-License-Identifier: Apache-2.0

export const ParseCase = (s: string) => {
  const ss = s.split("-")
  let final = ""
  if (ss.length > 0) {
    for (const word of ss) {
      final += (word.charAt(0).toUpperCase() + word.slice(1)).replace("-", "")
    }
  } else {
    final += s.charAt(0).toUpperCase() + s.slice(1)
  }
  return final
}

export const PascalToSnake = (s: string) => {
  return s
    .split(/(?=[A-Z])/)
    .join("-")
    .toLowerCase()
}

export const ToUpperCase = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const Chunks = (arrObj: Array<unknown>, size: number) => {
  return Array.from(new Array(Math.ceil(arrObj.length / size)), (_, i) =>
    arrObj.slice(i * size, i * size + size),
  )
}

export const slugify = (text: string) => {
  return text
    .toString()
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
}

export const isDefined = <T>(v: T | null | undefined): v is T =>
  v !== null && v !== undefined

export type NullableIfOptional<T> = T extends undefined ? T | null : T

export type DeepNullableIfOptional<T> = T extends object
  ? {
      [K in keyof T]: NullableIfOptional<DeepNullableIfOptional<T[K]>>
    }
  : NullableIfOptional<T>

export type NullableToOptional<T> = T extends null
  ? Exclude<T, null> | undefined
  : T

export type DeepNullableToOptional<T> = T extends object
  ? {
      [K in keyof T]: NullableToOptional<DeepNullableToOptional<T[K]>>
    }
  : NullableToOptional<T>
